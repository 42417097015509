/* You can add global styles to this file, and also import other style files */
@import 'skin';
@import '../../_base-styles/bootstrap/bootstrap';
//@import "~@ng-select/ng-select/themes/default.theme.css";
//@import "~nouislider/distribute/nouislider.min.css";
@import '../../_base-styles/common/functions';
@import '../../_base-styles/common/variables';
@import '../../_base-styles/common/mixins';
@import '../../_base-styles/forms';
@import '../../_base-styles/buttons';
@import '../../_base-styles/slider';
@import '../../_base-styles/core';
@import '../../_base-styles/config-map';
