$main-color: #ec0000;

/* FOOTER */
$logo-footer: '../../../../assets/santander/images/logo-footer.png';
$avatar-chatbot: '../santander/images/avatar.jpg';
$footer-background: #565962;
$footer-cliente-background: #000000;
$app-santander-color: #ffffff;
$app-santander-color-hover: #ffffff;
$border-hr: #ffffff;
$footer-h3-color: #999999;
$box-accordion-color: #ffffff;
$tel-title-color: #ffffff;
$tel-title-border-color: #999999;
$tel-title-backgroud-hover: #4e5158;
$h4-arrow-color: #ffffff;

$footer-mobile-color: #ffffff;
