.group-buttons {
  width: 100%;
  margin: 4.3rem 0 2.1rem;
}

.button-default {
  width: 300px;
  margin: 0 auto;
  display: block;
  background-color: $main-color;
  color: #fff;
  border-radius: 80px;
  padding: 1.4rem 1.2rem;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0;
  font-size: 1.7rem;
  border: 0;
  cursor: pointer;

  &.button-black {
    background-color: #4d4d4d;
  }

  &[disabled] {
    opacity: 0.7;
  }
}

.button-back {
  @include clear-button;

  strong {
    font-size: 1.4rem;
    margin-left: 1rem;
    float: left;
    text-transform: uppercase;
    color: #999;
    font-weight: 700;
    line-height: 3.6rem;
  }

  span {
    font-size: 3.4rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    line-height: 3.5rem;
    color: #999;
    background-color: #fff;
    text-align: center;
    box-shadow: 0 2px 6px 0 #e2e8f7;
    float: left;
  }
}

@media #{$mobile} {
  .button-default {
    max-width: 250px;
    width: 100%;
    font-size: 2.5rem;
    padding: 1.8rem 1.2rem;
  }

  .button-back {
    strong {
      font-size: 2rem;
      line-height: 4.6rem;
    }

    span {
      width: 4.5rem;
      height: 4.5rem;
      line-height: 4.5rem;
      font-size: 4rem;
    }
  }
}
