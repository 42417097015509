@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

* {
  outline: none;
}

body {
  // background-color: #333 !important;
  background-color: #fff !important;
}

section {
  padding: 4rem;
  color: #3f4153;
  &.section-white {
    background: #ffffff !important;
  }
  &.section-gray {
    background: #f9f9f9;
    border-width: 1px 0;
    border-style: solid;
    border-color: #e3e9fa;
  }
  &.main {
    background: #f1f0ee;
    h1 {
      font-size: 4.4rem;
      font-weight: 500;
      display: block;
      margin: 0;
    }
    h2 {
      font-size: 2.6rem;
      font-weight: 400;
      letter-spacing: -0.6px;
      margin: 1rem 0;
      display: block;
    }
    h2.sub {
      font-size: 2.2rem;
    }
  }
  &.caracteristica-proposta {
    h2 {
      font-size: 2.4rem;
      font-weight: 500;
      &.sub {
        font-size: 2.1rem;
        font-weight: 400;
      }
    }
  }
  &.solicite-analise {
    p {
      font-size: 2.6rem;
      font-weight: 300;
      margin: 5rem 0;
      display: block;
      text-align: center;
    }
  }
}

@media #{$desktop} {
  html {
    font-size: $escala-desktop;
  }
  .container {
    width: rem(960, 10);
  }
}
@media #{$mobile} {
  html {
    font-size: $escala-mobile;
  }
  section {
    &.main,
    &.caracteristica-proposta {
      h2 {
        font-size: 3.5rem;
        &.sub {
          font-size: 2.2rem;
        }
      }
    }
  }
}

@media #{$mobile-xs} {
  html {
    font-size: $escala-mobile-xs;
  }
}

.vivereNaoVisivel {
  display: none;
  visibility: hidden;
}

.text-error {
  color: #ed1d24 !important;
}
