.slider {
  position: relative;
  width: 100%;
  // padding: 5rem 0;
  padding: 1rem 0;
  .valor-total {
    text-align: right;
    color: #3f4153;
    font-size: 1.5rem;
    // padding-bottom: 2rem;
    .valor-total__titulo {
      // font-size: 1.2rem;
      // font-weight: 300;
      font-weight: 700;
      letter-spacing: 0.1rem;
    }
    .valor-total__valor {
      // font-size: 2rem;
      // font-weight: bold;
      font-weight: 400;
      letter-spacing: 0.1rem;
    }
  }
  .entrada-minima {
    color: #3f4153;
    padding: 0 2rem 0 5rem;
    display: inline-block;
    border-right: 1px dashed #3f4153;
    position: relative;
    &:before {
      content: '';
      width: 0.8rem;
      height: 0.8rem;
      position: absolute;
      bottom: 0;
      right: -4px;
      background-color: #3f4153;
    }
    .entrada-minima__titulo {
      font-size: 1.5rem;
      font-weight: 300;
      display: block;
      margin-top: 1rem;
    }
    .entrada-minima__valor {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
  .ng2-nouislider {
    margin: 0;
  }
  .noUi-target {
    background-color: #27bfa2;
    border: 0;
    border-radius: 25px;
    box-shadow: none;
    .noUi-connects {
      border-radius: 25px;
      .noUi-connect {
        background-color: #abc111;
      }
    }
    .noUi-handle {
      cursor: pointer;
      border: 1px solid #3f4153;
      border-radius: 50%;
      width: 34px;
      height: 34px;
      top: -0.9rem;
      &:before,
      &:after {
        display: none;
      }
      &.noUi-active {
        border: 0.75rem solid rgba(23, 176, 163, 0.2);
        box-shadow: none;
      }
    }
  }
}
