.formulario {
  .container {
    max-width: rem(610, 10);
  }

  h2 {
    font-size: rem(32, 10);
    margin: 6.2rem 0 0.3rem;
    font-weight: 300;
    letter-spacing: -0.6px;

    &.sub {
      font-size: rem(27, 10);
      margin: 0 0 7px;
    }
  }
}

.form-group {
  position: relative;
  margin: 4.3rem 0 0;

  &.form-group-radio {
    margin: 1rem 0 0;
  }
}

form {
  label {
    padding-right: 26px;
  }
}

label {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: rem(17, 10);
  letter-spacing: 0.06666667em;
}

span {
  &.info {
    font-size: 2rem;
    padding: 0.4rem 0;
    font-weight: 300;
    border: none;
    background: transparent;
  }
}

input[type='text'],
input[type='tel'],
input[type='password'] {
  font-size: 2rem;
  padding: 0.4rem 1.6rem;
  font-weight: 300;
  border: none;
  border-bottom: 1px solid #999999;
  display: block;
  width: 100%;
  background: transparent;
}

input[type='checkbox'],
input[type='radio'] {
  position: absolute;
  left: -9999px;

  & + label {
    cursor: pointer;
    display: block;
    font-size: rem(14, 10);
    padding-top: 0 !important;
    padding-left: 1rem !important;
    height: 2rem;
    position: relative;
    font-weight: normal;
    color: #3b4148;
    margin: 0;
    @include transition-animation(all, 280ms, ease);
    line-height: 2.3rem;
    text-align: left;

    &:before {
      content: '';
      left: -1rem;
      top: -2px;
      position: relative;
      float: left;
      height: 2.8rem;
      width: 2.8rem;
      background: #fff;
      border: 0.28rem solid #919292;
      border-radius: 10%;
      @include transition-animation(all, 280ms, ease);
    }
  }

  &:checked {
    & + label {
      &:before {
        content: '';
        font-size: 1rem;
        border: 0.18rem solid #abc111;
        background-color: #abc111;
      }

      &:after {
        font-family: 'Material Icons';
        content: '\E5CA';
        font-size: 1.6rem;
        line-height: 2.8rem;
        width: 2.8rem;
        height: 2.8rem;
        position: absolute;
        text-align: center;
        color: #fff;
        top: -2px;
        left: 0px;
      }
    }
  }
}

input[type='radio'] {
  & + label {
    color: #999;
    font-size: rem(17, 10);

    &::before {
      border-radius: 50%;
      border: 0.2rem solid #919292;
    }
  }

  &:checked {
    & + label {
      &:before {
        content: '';
        // border: 0.8rem solid #abc111;
        border: 0.8rem solid #ABC111;
        background-color: transparent;
      }

      &:after {
        content: '';
      }
    }
  }
}

.ng-select {
  margin-top: 1px;

  & + .help-block {
    display: none !important;
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    font-size: 2rem;
    padding: 0 1.6rem;
    font-weight: 300;
    border: none;
    border-bottom: 1px solid #999999;
    width: 100%;
    background: transparent;
    border-radius: 0;
    height: auto;
    min-height: 3.8rem;

    input[type='text'],
    input[type='tel'] {
      padding: 0 1.6rem;
    }

    .ng-value-container {
      padding-left: 0;
    }

    .ng-arrow-wrapper {
      .ng-arrow {
        right: 5px;
        position: absolute;
        border: 0;

        &:before {
          font-family: 'Material Icons';
          content: '\e313';
          font-size: 2rem;
        }
      }
    }
  }

  .ng-dropdown-panel {
    border: 0;
    background: #fff;

    .ng-dropdown-panel-items {
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      padding: 0 1.2rem;

      .ng-option {
        &.ng-option-marked,
        &.ng-option-selected {
          background: #f5f5f5;
          border-radius: 10rem;

          .ng-option-label {
            color: #abc111;
          }
        }

        @include transition-animation(all, 300ms, linear);
        margin: 1.2rem 0;
        padding: 0.8rem 1.6rem;

        .ng-option-label {
          font-size: 2rem;
          font-weight: 300;
          cursor: pointer;
          color: #333;
          white-space: normal;
        }
      }
    }
  }

  &.ng-invalid.ng-touched {
    .ng-arrow {
      color: #dc3545 !important;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      border-bottom: 2px solid #999999;
      background: transparent;
    }

    .ng-arrow-wrapper {
      .ng-arrow {
        &:before {
          content: '\e316';
        }
      }
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      box-shadow: none !important;
      border-color: #999999 !important;
    }
  }
}

p.disclaimer {
  font-size: 1.5rem;
  margin: 3.8rem 0 0;
  text-align: center;
}

@media #{$mobile} {
  .formulario {
    .container {
      max-width: 720px;
    }

    p.disclaimer {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: rem(60, 10);

    &.sub {
      font-size: rem(36, 10);
    }
  }

  label {
    font-size: 2.5rem;
    letter-spacing: rem(0.6, 10);
  }

  span {
    &.info {
      font-size: 2.5rem;
    }
  }

  input[type='text'],
  input[type='tel'] {
    font-size: 3rem;
    padding: 0.8rem 1.6rem;
  }

  input[type='checkbox'],
  input[type='radio'] {
    & + label {
      font-size: rem(30, 10);
      line-height: 3.3rem;

      &:before {
        width: 4.8rem;
        height: 4.8rem;
        margin-right: 1.5rem;
      }
    }
  }

  input[type='checkbox'] {
    &:checked {
      & + label {
        &:after {
          font-size: 3.6rem;
          line-height: 4.8rem;
          width: 4.8rem;
          height: 4.8rem;
        }
      }
    }
  }

  .ng-select {
    margin-top: 1px;

    .ng-select-container {
      padding: 1.4rem 1.6rem;

      .ng-placeholder {
        font-size: 3rem;
      }

      .ng-value {
        font-size: 3rem;
      }

      .ng-arrow-wrapper {
        .ng-arrow {
          &:before {
            font-size: 3rem;
          }
        }
      }
    }

    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          .ng-option-label {
            font-size: 3rem;
          }
        }
      }
    }
  }
}

input.text-uppercase {
  text-transform: uppercase;
}
input.text-uppercase::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
input.text-uppercase:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
input.text-uppercase::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
input.text-uppercase:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
input.text-uppercase::placeholder {
  /* Recent browsers */
  text-transform: none;
}
