// /*** CONFIGMAP.CSS ***/
// /* Arquivo de personalização SDK WEB Chatbot Santander - 2020/2021 */

// p,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-weight: Roboto, sans-serif;
// }

// .fab-container {
//   z-index: 999;
// }

// .chat-box-header {
//   background-color: #c00;
//   color: #ffffff;
//   font-weight: 700;
// }
@import 'skin';

.fab-buttons span.fab-rotate {
    background-image: url($avatar-chatbot);
}

