@mixin bg-timeline {
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(237, 238, 240, 1) 150%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(150%, rgba(237, 238, 240, 1))
  );
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(237, 238, 240, 1) 150%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(237, 238, 240, 1) 150%);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(237, 238, 240, 1) 150%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(237, 238, 240, 1) 150%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#edeef0', GradientType=0);
}

@mixin transition-animation($type, $time, $transition) {
  -webkit-transition: $type $time $transition;
  -moz-transition: $type $time $transition;
  -o-transition: $type $time $transition;
  transition: $type $time $transition;
}

@mixin color-steps-timeline($position) {
  background: -webkit-linear-gradient(to right, #abc111 $position, #d8d8d8 0) !important;
  background: -moz-linear-gradient(to right, #abc111 $position, #d8d8d8 0) !important;
  background: -o-linear-gradient(to right, #abc111 $position, #d8d8d8 0) !important;
  background: -ms-linear-gradient(to right, #abc111 $position, #d8d8d8 0) !important;
  background: linear-gradient(to right, #abc111 $position, #d8d8d8 0) !important;
}

@mixin clear-button {
  cursor: pointer;
  border: none;
  background: 0 0;
  outline: 0;
}
